$('.sec-title').each(function() {
    var $title = $(this).find('.sec-title_big');
    var titleText = $title.text().trim();
    var words = titleText.split(' ');
    var numWords = 0;

    if ($(this).hasClass('one-word')) {
        numWords = 1;
    } else if ($(this).hasClass('two-words')) {
        numWords = 2;
    } else if ($(this).hasClass('three-words')) {
        numWords = 3;
    } else if ($(this).hasClass('four-words')) {
        numWords = 4;
    }

    // Adiciona a tag <small> apenas se numWords for maior que 0
    if (numWords > 0) {
        var selectedWords = words.slice(0, numWords).join(' ');
        var newHtml = titleText.replace(selectedWords, '<small>' + selectedWords + '</small>');
        $title.html(newHtml);
    }
});