$(function() {
    $('.btn-toggle-menu').click(function(e) {

        $(this).toggleClass('active');
        
        $('.toggle-menu').slideToggle();
    });
});


function adjustMenuLinks() {
    if ($(window).width() < 1200) {
        // Modifica o href para 'javascript:;' para itens de menu com filhos
        $('.menu-item-has-children > a').attr('href', 'javascript:;');
        
        // Remove o manipulador de eventos existente para evitar duplicatas
        $('.menu-item-has-children > a').off('click');

        // Adiciona o evento de clique para toggle do submenu
        $('.menu-item-has-children > a').on('click', function() {
            // Aqui usamos o método slideToggle para um efeito visual, mas você pode usar .toggle() se preferir
            $(this).next('.sub-menu').slideToggle();
            return false; // Para evitar que a página role para o topo
        });
    }
}

// Chama a função ao carregar a página
adjustMenuLinks();

// Chama a função se a tela for redimensionada
$(window).resize(function() {
    adjustMenuLinks();
});

jQuery(document).ready(function($) {
    
    // Percorre cada item de menu que tenha submenus
    $('#menu-menu-principal .menu-item-has-children').each(function() {
        var $thisMenuItem = $(this); // Armazena o item atual do menu para uso posterior
        var $submenu = $(this).find('.sub-menu:first'); // Seleciona o primeiro submenu para checar
        var hasColumns = false;

        // Verifica se o submenu contém itens que são considerados 'colunas'
        $submenu.find('> li').each(function() {
            if ($(this).find('.sub-menu').length > 0) { // Verifica se o item tem seus próprios submenus, indicando uma 'coluna'
                hasColumns = true;
                return false; // Sai do loop se encontrar uma 'coluna'
            }
        });

        // Se o submenu não tiver 'colunas', adiciona uma classe ao submenu
        if (!hasColumns) {
            $submenu.addClass('no-columns'); // Adiciona a classe 'no-columns'. Substitua pelo que precisa.
            $thisMenuItem.addClass('parent-no-columns'); // Adiciona uma classe ao item de menu pai
        }
    });
});
